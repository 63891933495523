import { Datagrid, List, TextField, downloadCSV } from 'react-admin';
import { AdminTherapistRequestResponseDto, AdminTherapistUserResponseDto, TherapistEducationInfoResponseDto, TherapistPersonalInfoResponseDto, TherapistTherapiesInfoResponseDto, TherapistWorkingInfoResponseDto } from 'shared/api';
import { LABELS } from 'shared/constants/labels';
import jsonExport from 'jsonexport/dist';

const flatEducationInfo = (educationInfo: TherapistEducationInfoResponseDto) => {
  return {
    'educationInfo.education': educationInfo.education.map(note => note.university).join(','),
    'educationInfo.additionalEducation': educationInfo.additionalEducation?.map(note => note.organisation).join(',') || '',
  }
}

const flatPersonalInfo = (personalInfo: TherapistPersonalInfoResponseDto) => {
  return Object.entries(personalInfo).reduce((acc: any, entry) => {
    acc[`personalInfo.${entry[0]}`] = entry[1];
    return acc;
  }, {});
};

const flatTherapiesInfo = (therapiesInfo: TherapistTherapiesInfoResponseDto) => {
  return {
    'therapiesInfo.diseases': therapiesInfo.diseases.map(disease => disease.name).join(','),
    'therapiesInfo.specialisation': therapiesInfo.specialisation.join(','),
    'therapiesInfo.therapyMethods': therapiesInfo.therapyMethods.map(method => method.name).join(','),
  }
}

const flatUser = (user: AdminTherapistUserResponseDto) => {
  return Object.entries(user).reduce((acc: any, entry) => {
    acc[`user.${entry[0]}`] = entry[1];
    return acc;
  }, {});
};

const flatWorkingInfo = (workingInfo: TherapistWorkingInfoResponseDto) => {
  const data = Object.entries(workingInfo).reduce((acc: any, entry) => {
    acc[`workingInfo.${entry[0]}`] = entry[1];
    return acc;
  }, {});

  return {
    ...data,
    'workingInfo.languages': workingInfo.languages.join(','),
    'workingInfo.workWithReligions': workingInfo.languages.join(','),
  }
}

const exporter = (requests: AdminTherapistRequestResponseDto[]) => {
  const data = requests.map(request => ({
    ...flatUser(request.user),
    ...flatPersonalInfo(request.personalInfo),
    ...flatWorkingInfo(request.workingInfo),
    ...flatTherapiesInfo(request.therapiesInfo),
    ...flatEducationInfo(request.educationInfo),
  }));

  jsonExport(data, (err, csv) => { downloadCSV(csv, 'requests') });
}

export const TherapistsRequestsList = () => {
  return (
    <List exporter={exporter}>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="personalInfo.firstname" label={LABELS.firstname} />
        <TextField source="personalInfo.lastname" label={LABELS.lastname} />
      </Datagrid>
    </List>
  );
};
